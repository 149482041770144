import React from "react"
import SEO from "../components/seo"

import { CardLeft, CardRight, Line } from "../components/elements"
import Layout from "../components/layout"
import {
  HeaderPage,
  IntroSection,
  Services,
  ProductDetails,
  ReasonSection,
  ServiceDetail,
} from "../components/structure"

import seo from "../images/digital/seo.svg"
import content from "../images/digital/content.svg"
import email from "../images/digital/email.svg"
import leadmarketing from "../images/digital/leadmarketing.svg"
import ppc from "../images/digital/ppc.svg"
import sem from "../images/digital/sem.svg"
import smm from "../images/digital/smm.svg"
import smo from "../images/digital/smo.svg"
import video from "../images/digital/video.svg"
import headerIcon from "../images/digital/headerIcon.svg"

export default function DigitalMarketingServices() {
  return (
    <Layout>
      <SEO title="Digital Marketing Services" />

      <HeaderPage
        src={headerIcon}
        color={props => props.theme.procolors.digitalDark}
        bgColor={props => props.theme.procolors.digitalLight}
        title="Digital marketing designed for you."
        tagline="Let our customised digital marketing strategies take your business to new heights."
        catchy="Digital Marketing Services"
      />
      <Line />
      <ServiceDetail>
        <IntroSection
          title="Digital marketing designed for you."
          details=" We help our clients to drive business growth through our digital marketing services. Through a variety of different platforms, from social media to Google Ads to email marketing, we help businesses to get more customers and increase their sales. Our strategies deliver real results and will help your company to prosper."
          color={props => props.theme.procolors.digitalDark}
        />
        <ProductDetails
          to="/contact/"
          title="Our Digital Marketing Services Include Below Key Services"
          color={props => props.theme.procolors.digitalDark}
        >
          <CardLeft
            alt
            title="Search engine optimisation (SEO)"
            detail="Increase your online visibility and website traffic with our proven search engine optimisation methods."
            src={seo}
          />
          <CardRight
            alt
            title="Pay-per-click Google Ads (PPC)"
            detail="Find new customers with this successful advertising solution and watch your business grow before your eyes."
            src={ppc}
          />
          <CardLeft
            alt
            title="Search engine marketing (SEM)"
            detail="With our search engine marketing, your website will rank higher in search engines and give your business the boost it needs."
            src={sem}
          />
          <CardRight
            alt
            title="Social media optimisation (SMO)"
            detail="Use our effective social media optimisation strategies to generate publicity and help your brand soar to new heights."
            src={smo}
          />
          <CardLeft
            alt
            title="Email campaign marketing"
            detail="Convert leads and win new customers with our email campaign marketing services."
            src={email}
          />
          <CardRight
            alt
            title="Social media marketing (SMM)"
            detail="Our social media marketing services will help your brand to stand out on social media."
            src={smm}
          />
          <CardLeft
            alt
            title="Content marketing"
            detail="Get your content into the right hands and boost your business by using our effective content marketing strategies."
            src={content}
          />
          <CardRight
            alt
            title="Video SEO"
            detail="Help your videos to rank on the relevant search engine pages with our efficient Video SEO strategies."
            src={video}
          />
          <CardLeft
            alt
            title="Lead generation marketing"
            detail="Our lead generation marketing service will leave your target audience feeling nurtured and valued, eventually turning them into loyal customers."
            src={leadmarketing}
          />
        </ProductDetails>
        <ReasonSection
          borderColor={props => props.theme.procolors.digitalLight}
          title="Why Do You Need Digital Marketing Services?"
          details=" We help our clients to drive business growth through our digital marketing services. Through a variety of different platforms, from social media to Google Ads to email marketing, we help businesses to get more customers and increase their sales. Our strategies deliver real results and will help your company to prosper."
          details1="Digital marketing services typically cost less than traditional marketing and can help your business to reach a wider audience. They also provide more opportunities to engage with your customers in a live setting, create brand loyalty, and are easy to track."
        />
      </ServiceDetail>
      <Line />
      <Services />
    </Layout>
  )
}
